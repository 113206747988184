.toast {
  width: 30rem;
  min-height: 5rem;

  padding: 1rem;

  margin-bottom: 0.5rem;

  border-radius: 6px;

  background-image: none !important;

  .toast-title {
    font-size: var(--font-size-xs);
    margin-bottom: 0.5rem;
    color: var(--color-neutral-white);
  }

  .toast-message {
    font-size: var(--font-size-xxs);
    color: var(--color-neutral-white);
  }

  &--success {
    @extend .toast;

    background-color: var(--color-positive) !important;
  }

  &--error {
    @extend .toast;

    background-color: var(--color-danger) !important;
  }

  &--info {
    @extend .toast;

    background-color: var(--color-info) !important;
  }
}
