.table-primary {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

  thead {
    border-bottom: 1px solid var(--color-primary-300);

    tr {
      height: 4rem;
    }
  }

  tbody {
    tr {
      height: 10rem;
      border-bottom: 1px solid var(--color-primary-300);
    }
  }

  th {
    text-align: center !important;
    color: var(--color-neutral-500);
    font-weight: 300;

    &:first-child {
      text-align: left !important;
    }
  }

  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center !important;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
    max-width: 20rem;

    .span-xxxs {
      font-size: var(--font-size-xxxs);
    }

    .span-xs {
      font-size: var(--font-size-xs);
    }

    .with-image {
      display: flex;
      align-items: center;
      gap: 1rem;

      figure {
        margin: 0;
        padding: 0;
        width: 5rem;
        height: 5rem;
        overflow: hidden;
        border: 1px solid var(--color-primary-300);
        border-radius: 4px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .main-info {
        flex: 1;
        display: flex;
        flex-direction: column;

        span:first-child {
          font-size: var(--font-size-xs);
          color: var(--color-primary-700);
          font-weight: 300;
        }

        span:last-child {
          font-size: var(--font-size-xs);
          color: var(--color-neutral-500);
          font-weight: 300;

          b {
            color: var(--color-highlight-500);
          }
        }
      }
    }
  }
}

.table-secondary {
  @extend .table-primary;

  border-spacing: 0;
  border-collapse: separate;
  border-radius: 1rem;
  border: 1px solid var(--color-primary-200);

  overflow: hidden;

  background-color: var(--color-primary-100);

  th {
    color: var(--color-neutral-700);
    font-weight: 500;

    text-align: left !important;

    user-select: none;

    border-bottom: 1px solid #dddddd;
  }

  tr {
    height: 3.5rem !important;

    transition: 150ms;
  }

  tbody {
    tr {
      &:hover {
        background-color: var(--color-neutral-200);
      }

      &:not(:last-child) td {
        border-bottom: 1px solid #dddddd;
      }
    }
  }
}
