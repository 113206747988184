.tooltip {
  width: max-content;

  background-color: var(--color-neutral-500);

  color: var(--color-neutral-white);
  font-size: var(--font-size-xxs);
  padding: 0.6rem;

  border-radius: 1rem !important;
  border: none !important;

  margin-left: 1rem !important;

  .ngxp__arrow {
    border-color: var(--color-neutral-500) !important;
  }

  &_right {
    @extend .tooltip;

    .ngxp__arrow {
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  &_top {
    @extend .tooltip;

    .ngxp__arrow {
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      border-left-color: transparent !important;
    }
  }

  &_left {
    @extend .tooltip;

    .ngxp__arrow {
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
    }
  }

  &_bottom {
    @extend .tooltip;

    &_white {
      @extend .tooltip_bottom;

      z-index: 3;

      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3) !important;

      border-radius: 0.5rem !important;
      border: none !important;

      background-color: var(--color-neutral-white);
      color: var(--color-neutral-700);

      .ngxp__arrow {
        border-color: var(--color-neutral-white) !important;
      }
    }

    .ngxp__arrow {
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      border-left-color: transparent !important;
    }
  }
}
