.select {

  &--primary {
    position: relative;
    height: 100%;
    flex: none;
    order: 1;
    flex-grow: 0;

    &::after {
      position: absolute;
      content: "\276F";
      font-size: var(--font-size-xxs);
      top: 10px;
      right: 15px;
      color: var(--color-primary-400);
      transform: rotate(90deg);
      cursor: pointer;
      pointer-events: none;
    }

    select {
      background: var(--color-neutral-white);
      padding: 8px 35px 8px 20px;
      border: 1px solid var(--color-neutral-50);
      font-weight: 400;
      font-size: var(--font-size-xxs);
      height: 100%;
      width: 100%;
      line-height: 20px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      cursor: pointer;
      color: var(--color-primary-400);
      min-height: 2.5rem;
      outline: none;
    }
  }
}
