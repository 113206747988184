:root {
  --animation-time-menu: 250ms;
  --animation-time-remove-product: 500ms;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes slideOutRight {
  40% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
