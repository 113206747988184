.button {
  padding: 1rem 1rem;
  cursor: pointer;
  outline: 0;
  border: 0;
  border-radius: 6px;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.12);

  transition: filter 125ms;

  &:hover {
    filter: brightness(1.05)
  }

  &-primary {
    @extend .button;

    color: var(--color-neutral-white);
    background: var(--color-neutral-700);
    font-weight: 500;
    font-size: var(--font-size-xs);

    &_light {
      font-size: var(--font-size-xxs);
      background-color: var(--color-neutral-500);
    }
  }

  &-secondary {
    @extend .button;
    background: var(--color-highlight);
    color: var(--color-neutral-white);
  }

  &-neutral {
    @extend .button;
    color: var(--color-neutral-white);
    background: var(--color-neutral-500);
  }

  &-danger {
    @extend .button;
    color: var(--color-neutral-white);
    background: var(--color-danger);
  }

  &-neutral {
    @extend .button;
    color: var(--color-neutral-white);
    background: var(--color-neutral-500);
  }

  &-danger {
    @extend .button;
    color: var(--color-neutral-white);
    background: var(--color-danger);
  }

  &-default {
    @extend .button;

    font-size: var(--font-size-xxs);
    color: var(--color-neutral-700);

    background-color: transparent;

    width: fit-content !important;

    box-shadow: none;

    &_shadow {
      @extend .button-default;
      width: 100% !important;

      border: 2px solid #F2F4F7;
      border-radius: 7px;
      filter: drop-shadow(1px 3px 8px rgba(0, 0, 0, 0.25));

      font-size: var(--font-size-xs);
      font-weight: 600;
      color: var(--color-primary-500);
    }
  }

  &_loading {
    position: relative;
    opacity: 0.8;
    pointer-events: none;

    span {
      opacity: 0;
    }
  }

  &-danger {
    @extend .button;

    background-color: var(--color-danger);

    font-size: var(--font-size-xxs);
    font-weight: 500;
    color: var(--color-neutral-white);
  }

  &_loading {
    opacity: 0.8;
    span {
      opacity: 0;
    }
  }

  &-danger {
    @extend .button;

    background-color: var(--color-danger);

    font-size: var(--font-size-xxs);
    font-weight: 500;
    color: var(--color-neutral-white);
  }
}
