:root {
  --base-pixel-to-rem: 16;

  // fontSizePxToRem = calc(font-size-in-pixel / relation-between-rem-and-px) * 1rem;
  --font-size-display: calc((80 / var(--base-pixel-to-rem) * 1rem));
  --font-size-xxxl: calc((64 / var(--base-pixel-to-rem) * 1rem));
  --font-size-xxml: calc((58 / var(--base-pixel-to-rem) * 1rem));
  --font-size-xxl: calc((48 / var(--base-pixel-to-rem) * 1rem));
  --font-size-lg: calc((32 / var(--base-pixel-to-rem) * 1rem));
  --font-size-md: calc((24 / var(--base-pixel-to-rem) * 1rem));
  --font-size-sm: calc((20 / var(--base-pixel-to-rem) * 1rem));
  --font-size-xs: calc((16 / var(--base-pixel-to-rem) * 1rem));
  --font-size-xxs: calc((14 / var(--base-pixel-to-rem) * 1rem));
  --font-size-xxxs: calc((12 / var(--base-pixel-to-rem) * 1rem));
  --font-size-xxxxs: calc((10 / var(--base-pixel-to-rem) * 1rem));
  --font-size-xxxxxs: calc((8 / var(--base-pixel-to-rem) * 1rem));
}
