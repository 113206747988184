.input {
  border: 0.8px solid var(--color-neutral-100);
  border-radius: 0.4rem;

  width: 100%;
  height: 2.7rem;

  padding: 0 0.4rem;

  font-size: var(--font-size-xxs);

  &--primary {
    @extend .input;

    background-color: var(--color-neutral-50);
  }

  &--secondary {
    @extend .input;

    background: transparent;
  }

  &--icon {
    position: relative;

    app-icon {
      position: absolute;
      right: 1rem;
      bottom: 30%;

      color: var(--color-neutral-500);
    }
  }
}

.input-with-suffix-icon {
  position: relative;
  border: 0.8px solid var(--color-neutral-100);
  border-radius: 0.4rem;
  padding: 0 0.4rem;
  font-size: var(--font-size-xxs);
  background: transparent;

  & > .icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 3rem;
    height: 100%;
    font-size: var(--font-size-xs);
    font-weight: 300;
  }

  input {
    margin-left: 2.6rem;
    width: calc(100% - 2.6rem);
    border: none;
    border-left: 0.8px solid var(--color-neutral-100);
    border-radius: 0;
  }
}

.textarea {
  border: 1px solid var(--color-neutral-200);
  border-radius: 0.4rem;

  width: 100%;
  min-height: 10rem;

  padding: 0.5rem 1rem;

  font-size: var(--font-size-xxs);
  resize: none;
  outline: none;

  &:active, &:focus {
    border: 1px solid var(--color-neutral-200);
  }
}

.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  color: var(--color-neutral-700);
  font-size: var(--font-size-xxs);

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    max-width: 1rem;
    min-height: 1rem;
    max-height: 1rem;
    border-radius: 2px;
    outline: none;
    border: 1px solid var(--color-neutral-200);
    background: var(--color-neutral-100);
    margin: 0;
    cursor: pointer;

    &:before {
      content: "";
      display: block;
      width: 86%;
      height: 85%;
      margin: 8%;
      border-radius: 2px;
    }

    &:checked:before {
      background: var(--color-neutral-700);
    }
  }
}

.div-as-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  color: var(--color-neutral-700);
  font-size: var(--font-size-xxs);

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    min-width: 1rem;
    max-width: 1rem;
    min-height: 1rem;
    max-height: 1rem;
    border-radius: 2px;
    padding: 0.1rem;
    outline: none;
    background: var(--color-neutral-100);
    margin: 0;
    cursor: pointer;
    color: var(--color-neutral-white);

    &.checked {
      background: var(--color-highlight-500);
    }
  }
}
