:root {
  --scrollbar-width: 9px;
}

* {
  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
    background: var(--color-neutral-100) 0 0 no-repeat padding-box;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-primary-700) 0 0 no-repeat padding-box;
    border-radius: 7px;
  }
}
