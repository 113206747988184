.modal {
  animation: fadein 125ms;
  position: fixed;
  z-index: 999999;

  height: 100vh;
  width: 100vw;

  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  backdrop-filter: brightness(0.8);

  &-container {
    height: 83vh;
    width: 83vw;
    padding: 3.75rem 3rem 3rem;

    border-radius: 24px;
    background: var(--color-neutral-white);

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-weight: 500;
        font-size: var(--font-size-md);
      }

      app-icon {
        cursor: pointer;
        font-size: 1.5rem;
        margin-top: -1.5rem;
        color: var(--color-neutral-600);
      }
    }

    hr {
      border: 1px solid var(--color-primary-200);
    }
  }
}
