ngx-daterangepicker-material {
  .calendar.right {
    display: none !important;
  }

  .md-drppicker td.active, .md-drppicker td.active:hover {
    background-color: var(--color-highlight);
  }

  .md-drppicker .btn, .md-drppicker .btn:hover, .md-drppicker .btn:focus {
    background-color: var(--color-highlight);
    box-shadow: none;

    padding: 0 1rem;
  }
} 
