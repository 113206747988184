.card-primary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1.5rem;

  background-color: var(--color-neutral-50);
  border: 1px solid var(--color-primary-200);
  border-radius: 16px;

  header {
    display: flex;
    justify-content: space-between;

    span {
      font-weight: 400;
      font-size: var(--font-size-xxs);
    }

    h2 {
      font-weight: 400;
      font-size: var(--font-size-sm);
      margin: 0;
    }

    .see-all {
      display: flex;
      align-items: center;

      cursor: pointer;
      user-select: none;
      color: var(--color-neutral-500);
      font-size: var(--font-size-xs);

      &:hover {
        opacity: 0.8;
      }

      app-icon {
        font-size: .6rem;
        margin-left: .25rem;
      }
    }
  }
}

.card-secondary {
  @extend .card-primary;

  border: none !important;
  border-radius: 0 !important;
}

.dropzone {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  color: var(--color-neutral-700);
  font-size: var(--font-size-sm);
  background: var(--color-neutral-50);
  border-radius: 16px;
  border: 1px dashed var(--color-neutral-500);
  overflow: hidden;

  &-inactive {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-active {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background: var(--color-neutral-50);
    pointer-events: none;
  }

  .display-files {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: var(--font-size-xxs);
    padding: 1rem 0 0.2rem 0;

    &--content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 7.5rem);
      grid-gap: 0.5rem 1rem;
      flex: 1;
      padding: 0 1rem;
      width: 100%;

      .highlight-file {
        border: 1px dashed var(--color-highlight-500);
        border-radius: 8px;
        cursor: grab;
      }
    }

    &--footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 4rem;
      padding: 0 1rem;
      border-top: 1px solid var(--color-neutral-400);

      &-buttons {
        display: flex;
        gap: 0.5rem;

        button {
          white-space: nowrap;
        }
      }
    }
  }

  .drag-and-drop-text {
    font-weight: 400;
    margin: 0.5rem 0;

    .drop-click {
      color: var(--color-highlight-500);
      cursor: pointer;
    }
  }

  .supporting-file-types {
    font-weight: 400;
    font-size: var(--font-size-xs);
    color: var(--color-neutral-500);
    margin: 0;
    padding: 0;
  }

  .file-dropzone {
    margin: 0;
    padding: 0;

    & > app-icon {
      font-weight: 400;
      font-size: var(--font-size-md);
      color: var(--color-neutral-500);
    }
  }
}
