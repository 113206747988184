@use "colors";
@use "cards";
@use "tooltips";
@use "fonts";
@use "modals";
@use "animations";
@use "buttons";
@use "scroll";
@use "toasts";
@use "inputs";
@use "table";
@use "calendar";
@use "selects";

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;600;700;900&display=swap");

* {
  font-family: 'Lexend', sans-serif;
  box-sizing: border-box;
}

:root {
  --footer-height: 5rem;
}

app-menu-visitor {
  position: absolute;
  top: 0;
  right: 0;
  overflow-x: hidden;
}

app-dash {
  display: block;
  width: calc(100vw + var(--scrollbar-width));
}

app-icon {
  display: block;
}

.ball {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-highlight-500);
}

.hr {
  border: 1px solid var(--color-primary-200);
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: var(--color-neutral-600);
  transition: background-color 5000s ease-in-out 0s;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-found-message {
  @extend .d-flex-center;

  z-index: 0;

  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


.disabled {
  pointer-events: none;
  opacity: 0.5;
}

#container--product-remarks--section--text--html {
  margin: 1rem 0;
  h1, h2 {
    color: var(--color-neutral-500);
    margin: 0;
  }

  p {
    white-space: pre-line;
    line-height: 21.6px;
    margin: 0;
    font-size: var(--font-size-xs);
    font-weight: 400;
    color: var(--color-neutral-500);
  }
}

popper-content {

  .ngxp__container {
    display: block !important;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 99;

    &[aria-hidden="true"] {
      visibility: hidden;
    }
  }
}